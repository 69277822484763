<template>
  <v-tooltip :disabled="disabledTooltip" top v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on">
        <!-- remarks: v-badge :icon cannot be used, since has issues at production   -->
        <!-- :icon="badgeRecordIcon(record, infoOnly)"-->
        <v-badge
          :left="badgeLeft"
          :overlap="badgeOverlap"
          :bordered="badgeBordered"
          :value="showRecordBadge(record, infoOnly)"
          :color="badgeRecordColor(record, infoOnly)"
        >
          <template v-slot:badge>
            <!-- remarks: using font-awesome, since v-badge :icon has issues at production -->
            <font-awesome-icon
              v-if="showRecordBadge(record, infoOnly)"
              :icon="badgeRecordFontAwesomeIcon(record, infoOnly)"
            ></font-awesome-icon>
          </template>
          <slot name="default"></slot>
        </v-badge>
      </div>
    </template>
    <slot name="tooltip"></slot>
  </v-tooltip>
</template>

<script>
import { recordBadgeMixin } from "@/mixins/shared/record/recordBadgeMixin";
import { badgeableRecordMixin } from "@/mixins/shared/record/badgeableRecordMixin";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "RecordBadgeBase",
  mixins: [
    recordBadgeMixin,
    badgeableRecordMixin,
    recordIconMixin,
    slottableMixin
  ],
  props: {
    isHistoryMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isNewRecord() {
      return (this?.record?.id ?? -1) < 0;
    },
    badgeLeft() {
      return this.isNewRecord;
    },
    disabledTooltip() {
      return !this.hasSlot("tooltip");
    }
  }
};
</script>
