// model
import {
  colorRecordCheckedInClass,
  colorRecordCheckedOutClass,
  colorRecordCreatedClass,
  colorRecordLockedClass,
  colorRecordNewClass,
  colorMeetingPublishedClass,
  colorAgendaItemSubmittedClass,
  recordState
} from "@/model/record/recordModel";

// design
import { Color } from "@/design/colors/Color";

// design
import { colorMD, variantNumber, variantType } from "@/design/colors/mdColors";

// mixins
import { principalMixin } from "../user/principalMixin";

export const recordStateColorMixin = {
  mixins: [principalMixin],
  methods: {
    /**
     * Record State Color
     * @param record
     * @return {string|undefined}
     */
    recordStateColor(record) {
      switch (record?.stateId ?? -1) {
        case recordState.checkedOut: {
          return this.isUserStateOwner(record)
            ? colorRecordCheckedOutClass
            : colorRecordLockedClass;
        }
        case recordState.checkedIn: {
          return colorRecordCheckedInClass;
        }
        case recordState.new: {
          return colorRecordNewClass;
        }
        case recordState.created: {
          return colorRecordCreatedClass;
        }
        case recordState.published: {
          return colorMeetingPublishedClass;
        }
        case recordState.submitted: {
          return colorAgendaItemSubmittedClass;
        }
        default: {
          return undefined;
        }
      }
    },
    /**
     * Record State Text Color
     * @param record
     * @return {string|undefined}
     */
    recordStateTextColor(record) {
      if (!record) return undefined;

      return new Color(
        colorMD.white,
        variantType.lighten,
        variantNumber.n3
      ).getClassColor();
    }
  }
};
