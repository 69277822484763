// design
import {
  iconLock,
  iconReadOnly,
  iconCheck,
  iconTrash,
  iconEdit,
  iconGlobe,
  iconPostMeeting,
  iconAccessDenied,
  iconFileCheckOut,
  iconOnHold,
  iconError,
  iconWarning
} from "@/design/icon/iconConst";

// plugins
import { createFontAwesomeSolidIcon, fasIconName } from "@/plugins/fontawesome";

// model
import {
  colorRecordDeletedClass,
  colorRecordLockedClass,
  colorRecordReadOnlyClass,
  colorRecordCheckedOutClass,
  recordState,
  colorMeetingPublishedClass,
  colorAgendaItemSubmittedClass,
  colorMeetingPostedClass,
  colorRecordInheritedReadOnlyClass
} from "@/model/record/recordModel";
import { IsCheckedOutForDigitalSigning } from "@/services/record/recordService";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user");

export const recordAttributeMixin = {
  data() {
    return {
      faIconName: fasIconName
    };
  },
  computed: {
    ...mapGetters({
      principal: "principal"
    })
  },
  methods: {
    hasAttributeIcon(record) {
      if (!record) return false;

      if (record.isLocked) return true;
      if (record.isReadOnly) return true;
      if (record.isDeleted) return true;
      if (
        (record?.localFile?.hasFile &&
          record.stateId !== recordState.checkedOut) ??
        false
      ) {
        return true;
      }

      switch (record.stateId) {
        case recordState.checkedOut: {
          return true;
        }
        case recordState.published:
        case recordState.submitted: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    recordAttributeIcon(record) {
      if (!record) return "undefined";

      // record.state takes priority
      switch (record.stateId) {
        case recordState.checkedOut: {
          return iconCheck;
        }
        case recordState.posted: {
          return iconPostMeeting;
        }
        case recordState.published:
        case recordState.submitted: {
          return iconGlobe;
        }
      }

      if (record.isLocked) return iconLock;
      if (record.isReadOnly) return iconReadOnly;
      if (record.isDeleted) return iconTrash;
      if (
        (record?.localFile?.hasFile &&
          record.stateId !== recordState.checkedOut) ??
        false
      ) {
        return iconEdit;
      }

      if (!(this.record?.canUpdate ?? false)) {
        // accessDenied, checkedOut, inherited, invalidOperation, locked, notInitialized, onHold, readOnly, retained
        const reason = this.record?.cannotUpdateReason;
        if (reason) {
          if (reason.accessDenied) return iconAccessDenied;
          else if (reason.checkedOut) return iconFileCheckOut;
          else if (reason.locked) return iconLock;
          else if (reason.readOnly) return iconReadOnly;
          else if (reason.onHold) return iconOnHold;
          else if (reason.notInitialized) return iconError;
          else if (reason.retained) return iconWarning;
          else if (reason.invalidOperation) return iconError;
        }
      }

      return undefined;
    },
    /**
     * record Attribute Font Awesome Icon
     * @param record
     * @return {{iconName: string, prefix: string}|undefined}
     */
    recordAttributeFontAwesomeIcon(record) {
      if (!record) return createFontAwesomeSolidIcon("");

      // record.state takes priority
      switch (record.stateId) {
        case recordState.checkedOut: {
          return IsCheckedOutForDigitalSigning(record)
            ? createFontAwesomeSolidIcon(fasIconName.signOut)
            : createFontAwesomeSolidIcon(fasIconName.check);
        }
        case recordState.posted: {
          return createFontAwesomeSolidIcon(fasIconName.clock);
        }
        case recordState.published:
        case recordState.submitted: {
          return createFontAwesomeSolidIcon(fasIconName.globe);
        }
      }

      if (record.isLocked) return createFontAwesomeSolidIcon(fasIconName.lock);
      if (record.isReadOnly) return createFontAwesomeSolidIcon(fasIconName.eye);
      if (record.isDeleted)
        return createFontAwesomeSolidIcon(fasIconName.trash);
      if (
        (record?.localFile?.hasFile &&
          record.stateId !== recordState.checkedOut) ??
        false
      )
        return createFontAwesomeSolidIcon(fasIconName.pencil);

      if (!(this.record?.canUpdate ?? false)) {
        // accessDenied, checkedOut, inherited, invalidOperation, locked, notInitialized, onHold, readOnly, retained
        const reason = this.record?.cannotUpdateReason;
        if (reason) {
          if (reason.accessDenied)
            return createFontAwesomeSolidIcon(fasIconName.eyeSlash);
          else if (reason.checkedOut)
            return createFontAwesomeSolidIcon(fasIconName.check);
          else if (reason.locked)
            return createFontAwesomeSolidIcon(fasIconName.lock);
          else if (reason.readOnly)
            return createFontAwesomeSolidIcon(fasIconName.eye);
          else if (reason.onHold)
            return createFontAwesomeSolidIcon(fasIconName.hand);
          else if (reason.notInitialized)
            return createFontAwesomeSolidIcon(fasIconName.xmark);
          else if (reason.retained)
            return createFontAwesomeSolidIcon(fasIconName.triangleExclamation);
          else if (reason.invalidOperation)
            return createFontAwesomeSolidIcon(fasIconName.xmark);
        }
      }

      return createFontAwesomeSolidIcon("");
    },
    recordAttributeColor(record) {
      if (!record) {
        return undefined;
      }

      // record.state takes priority
      switch (record.stateId) {
        case recordState.posted:
          return colorMeetingPostedClass;
        case recordState.published: {
          return colorMeetingPublishedClass;
        }
        case recordState.submitted: {
          return colorAgendaItemSubmittedClass;
        }
      }

      if (record.isReadOnly) return colorRecordReadOnlyClass;
      if (record.isLocked) return colorRecordLockedClass;
      if (record.isDeleted) return colorRecordDeletedClass;
      if (
        ((record.localFile?.hasFile ?? false) &&
          (record.stateId ?? -1) !== recordState.checkedOut) ??
        false
      ) {
        return colorRecordCheckedOutClass;
      }

      if (!(this.record?.canUpdate ?? false)) {
        return colorRecordInheritedReadOnlyClass;
      }

      return undefined;
    }
  }
};
