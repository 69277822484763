// model
import { recordState } from "@/model/record/recordModel";
import { colorRecordNewClass } from "@/model/record/recordModel";

// plugins
import { createFontAwesomeSolidIcon, fasIconName } from "@/plugins/fontawesome";

// design
import { colorTheme } from "@/design/colors/mdColors";
import { iconAsterisk, iconInfo } from "@/design/icon/iconConst";

// mixins
import { recordAttributeMixin } from "@/mixins/shared/record/recordAttributeMixin";
import { recordStateColorMixin } from "@/mixins/shared/record/recordStateColorMixin";

export const badgeableRecordMixin = {
  mixins: [recordAttributeMixin, recordStateColorMixin],
  methods: {
    /**
     * Show Record Badge
     * @param {{id:number, isReadOnly:boolean, isLocked:boolean}} record
     * @param {boolean} infoOnly display information Badge
     * @return {boolean}
     */
    showRecordBadge(record, infoOnly = false) {
      //return !!this.badgeRecordIcon(record, infoOnly);
      return (
        !!this.badgeRecordFontAwesomeIcon(record, infoOnly)?.iconName &&
        !this.isHistoryMode
      );
    },
    /**
     * Badge Record Icon
     * @param {{id:number, isReadOnly:boolean, isLocked:boolean}} record
     * @param {boolean} infoOnly display information Badge icon
     * @return {string|undefined}
     */
    badgeRecordIcon(record, infoOnly = false) {
      if (infoOnly) {
        return iconInfo;
      }

      return (record?.id ?? -1) < 0
        ? iconAsterisk
        : this.recordAttributeIcon(record);
    },
    /**
     * badge Record Font Awesome Icon
     * @param record
     * @param {boolean} infoOnly display information Badge icon
     * @return {{iconName: string, prefix: string}|undefined}
     */
    badgeRecordFontAwesomeIcon(record, infoOnly = false) {
      if (infoOnly) {
        return createFontAwesomeSolidIcon(fasIconName.info);
      }

      if ((record?.id ?? -1) < 0) {
        return createFontAwesomeSolidIcon(fasIconName.asterisk);
      }

      return this.recordAttributeFontAwesomeIcon(record);
    },
    /**
     * Badge Record Color
     * @param {{id:number, isReadOnly:boolean, isLocked:boolean, stateId:number}} record
     * @param {boolean} infoOnly display information Badge
     * @return {string|*|undefined}
     */
    badgeRecordColor(record, infoOnly = false) {
      if (infoOnly) {
        return colorTheme.info;
      }

      if ((record?.id ?? -1) < 0) {
        return colorRecordNewClass;
      }

      return (record?.stateId ?? -1) === recordState.checkedOut
        ? this.recordStateColor(record)
        : this.recordAttributeColor(record);
    }
  }
};
